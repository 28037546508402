<template>
    <user-location-section
        class="user-location-section-module"
        :label="attributes.label"
        :button-text="attributes.userLocation_button_text"
        :redirect="redirect"
    />
</template>

<script>
    import UserLocationSection from '../../../organisms/UserLocationSection/UserLocationSection';

    export default {
        name: 'user-location-section-module',

        components: { UserLocationSection },

        props: {
            attributes: {
                type: Object,
                required: true,
                default: () => ({}),
            },
        },

        computed: {
            /**
             * Returns the redirect.
             *
             * @returns {string}
             */
            redirect() {
                return this.attributes.redirect
                    ?.data
                    ?.attributes
                    ?.uri
                    || '';
            },
        },
    };
</script>
