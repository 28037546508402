<template>
    <div class="user-location-form">
        <form
            @submit.prevent="handleSubmit"
            v-test:form
        >
            <text-input
                id="address"
                :label="label"
                placeholder="enter town or postcode"
                name="address"
                :disabled="states.loading"
                :errors="states.error"
                autocomplete="postal-code"
                v-model="formData.location"
            >
                <template #button>
                    <b-button
                        variant="primary"
                        :disabled="states.loading"
                        type="submit"
                        v-test:submitButton
                    >
                        {{ buttonText }}
                    </b-button>
                </template>
            </text-input>
        </form>
    </div>
</template>

<script>
    import TextInput from '../../atoms/inputs/TextInput/TextInput';
    import { Loader } from '@googlemaps/js-api-loader';
    import { mapMutations } from 'vuex';

    export default {
        name: 'user-location-form',

        components: { TextInput },

        props: {
            label: {
                type: String,
                required: true,
            },

            buttonText: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                formData: {
                    location: this.$store.state.user.location,
                },
                states: {
                    loading: false,
                    error: [],
                },
            };
        },

        methods: {
            ...mapMutations('user', ['setLocation']),

            /**
             * Load the google api.
             */
            async loadGoogleScript() {
                if (this.google) {
                    return;
                }

                if (window.google) {
                    this.google = window.google;
                    return;
                }

                const loader = new Loader({
                    apiKey: this.$config.GOOGLE_API_KEY,
                    version: 'weekly',
                    libraries: ['places'],
                });

                this.google = await loader.load();
            },

            /**
             * Handle the form submission.
             */
            async handleSubmit() {
                this.states.error = [];
                this.states.loading = true;

                try {
                    await this.loadGoogleScript();

                    let {location, retry} = await this.lookupLocation();
                    if (retry) {
                        ({ location, retry } = await this.lookupLocation(true));
                    }

                    this.setLocation(location);

                    this.$emit('success');
                } catch (e) {
                    this.states.error = ['We could not find your location'];
                } finally {
                    this.states.loading = false;
                }
            },

            /**
             * Lookup the provided location via google geocoder.
             *
             * @param {boolean} addPrefix
             *
             * @returns {Promise<{lat: string|number, lng: string|number, location: string}>}
             */
            lookupLocation(addPrefix) {
                const { Geocoder } = this.google.maps;

                return new Promise((resolve, reject) => {
                    (new Geocoder).geocode({
                        address: addPrefix ? `${ this.formData.location }, uk` : this.formData.location,
                    }, (results, status) => {
                        if (status === 'OK') {
                            const [{
                                geometry,
                                formatted_address,
                                partial_match,
                            }] = results;

                            if (partial_match) {
                                return reject('We could not find your location');
                            }

                            return resolve({
                                location: {
                                    location: formatted_address,
                                    lat: geometry.location.lat(),
                                    lng: geometry.location.lng(),
                                },
                            });
                        }

                        if (status === 'ZERO_RESULTS' && !addPrefix) {
                            resolve({
                                retry: true,
                            });
                        }

                        reject(status);
                    });
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    form {
        border-radius: 141px;
        background-color: secondary-palette(7);
        padding: 24px;

        @include media-breakpoint-up(md) {
            padding: 24px 64px;
        }
    }

    .text-input.text-input {
        margin: 0;

        ::v-deep .form-control {
            border-radius: 20px;
            padding-left: rem(19px);
            padding-right: rem(19px);

            &:not(:focus):not(.is-invalid) {
                border-color: #ffffff;
            }
        }

        ::v-deep .label.label {
            font-size: rem(24px);
            text-align: center;
            display: block;
            font-weight: bold;
            color: primary-palette(2);
            line-height: inherit;
            margin-bottom: rem(8px);

            @include media-breakpoint-up(md) {
                text-align: left;
            }
        }
    }

    .btn {
        border-radius: 0 20px 20px 0;

        @include media-breakpoint-up(md) {
            min-width: 135px;
        }
    }
</style>
